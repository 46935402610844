// src/App.js
import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';

function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the Node.js server
    fetch('http://localhost:5000/api/data')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <Container className="my-5">
      <h1 className="text-center text-primary">User Information</h1>
      <Row>
        {data.map((item) => (
          <Col md={4} key={item.id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Text>Age: {item.age}</Card.Text>
                <Button variant="primary">View Profile</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default App;
